<template>
  <div>
    <div
      v-if="isRetrievingTasks"
      v-bind:class="[isRetrievingTasks ? 'is-loading' : '']"
    >
      <h1>Loading...</h1>
    </div>
    <div>
      <div class="container pb-180p">
        <v-data-table
          :headers="headers"
          :items="getTasks"
          :items-per-page="getTasksPerPage"
          hide-default-footer
          :page="getPage"
          class="elevation-1"
          @click:row="handleClickRow"
          show-select
          v-model="selected_tasks"
          style="cursor: pointer"
          multi-sort
          :sort-by="['status']"
          :sort-desc="[true]"
        >
          <template v-slot:top>
            <v-row
              no-gutters
              style="cursor: default"
            >
              <v-col class="d-flex justify-start align-center ml-3">
                <v-text-field
                  v-model="transactionDescription"
                  @change="handleSearchByDescription"
                  label="Search by description"
                  prepend-icon="mdi-magnify"
                  hint="Press enter to search"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col class="d-flex justify-end mr-3">
                <div>
                  <v-menu
                    ref="filterMenu"
                    v-model="isFilterMenuOpen"
                    :close-on-content-click="false"
                    offset-y
                    :nudge-left="150"
                    :max-width="300"
                    rounded
                  >

                    <template v-slot:activator="{ on: isFilterMenuOpen, attrs }">
                      <v-tooltip top>
                        <template v-slot:activator="{ on: filterTooltip }">
                          <v-btn
                            icon
                            color="rgba(0, 0, 0, 0.6)"
                            dark
                            class="mt-3 mb-3 mr-3"
                            v-bind="attrs"
                            v-on="{...isFilterMenuOpen, ...filterTooltip}"
                          >
                            <v-icon>mdi-filter-settings</v-icon>
                          </v-btn>
                        </template>
                        <span>Filters</span>
                      </v-tooltip>
                    </template>

                    <v-card>
                      <v-list>
                        <v-list-item>
                          <v-select
                            :value="getAssignementFilter"
                            :items="assignement_filters_options"
                            label="Assignment"
                            @change="updateAssignementFilter"
                            hide-details
                          >
                            <template #item="{ item }">
                              <div :class="item === 'myself' ? 'divider-bottom' : null">
                                {{ item.text || item }}
                              </div>
                            </template>
                          </v-select>
                        </v-list-item>

                        <v-list-item>
                          <v-select
                            :value="getStatusFilter"
                            :items="status_filters_options"
                            label="Status"
                            hide-details
                            @change="updateStatusFilter"
                          />
                        </v-list-item>

                        <v-list-item>
                          <v-select
                            v-model="selectedDataset"
                            :items="getDatasets"
                            item-text="name"
                            label="Filter by Dataset"
                            hide-details
                            @change="handleDatasetChange"
                            return-object
                            clearable
                          />
                        </v-list-item>
                        <v-list-item>
                          <v-select
                            :value="getSchemaFilter"
                            :items="getLabelSchemasWithAll"
                            label="Filter by Label Schema"
                            hide-details
                            @change="updateSchemaFilter"
                          />
                        </v-list-item>
                      </v-list>

                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                          text
                          @click="isFilterMenuOpen = false;"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="primary"
                          text
                          @click="onUpdateFilters(); isFilterMenuOpen = false;"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </div>
                <div>
                  <v-menu
                    ref="sortMenu"
                    v-model="isSortMenuOpen"
                    :close-on-content-click="false"
                    offset-y
                    :nudge-left="100"
                    :max-width="300"
                    rounded
                  >
                    <template v-slot:activator="{ on: isSortMenuOpen, attrs }">
                      <v-tooltip top>
                        <template v-slot:activator="{ on: sortTooltip }">
                          <v-btn
                            icon
                            color="rgba(0, 0, 0, 0.6)"
                            dark
                            class="mt-3 mb-3 mr-3"
                            v-bind="attrs"
                            v-on="{...isSortMenuOpen, ...sortTooltip}"
                          >
                            <v-icon>mdi-sort</v-icon>
                          </v-btn>
                        </template>
                        <span>Sort </span>
                      </v-tooltip>
                    </template>

                    <v-card>
                      <v-list>
                        <v-list-item>
                          <v-select
                            v-model="selectedSortingOrder"
                            :items="sortingOrders"
                            label="Sorting order"
                            hide-details
                            clearable
                          />
                        </v-list-item>

                        <v-list-item>
                          <v-select
                            v-model="selectedOrderBy"
                            :items="orderByValues"
                            label="Order by"
                            hide-details
                            clearable
                          />
                        </v-list-item>

                      </v-list>

                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                          text
                          @click="isSortMenuOpen = false;"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="primary"
                          text
                          @click="onUpdateSortFilters(); isSortMenuOpen = false;"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </div>
                <div>
                  <v-btn
                    v-if="selected_tasks.length === 0"
                    class="mt-3 mb-3"
                    color="secondary"
                    outlined
                    @click="selectAllTasks"
                  >
                    Select all
                  </v-btn>
                  <v-btn
                    v-if="selected_tasks.length > 0"
                    class="mt-3 mb-3"
                    color="secondary"
                    outlined
                    @click="deselectAllTasks"
                  >
                    Deselect all
                  </v-btn>
                  <span
                    class="text-center d-block"
                    style="font-size: 12px"
                  >
                    {{ selected_tasks.length }} tasks selected
                  </span>
                </div>

                <template>
                  <v-btn
                    class="mt-3 mb-3 ml-3"
                    color="primary"
                    outlined
                    :disabled="selected_tasks.length === 0"
                    @click="isAssignmentDialogOpen = true"
                  >
                    Assign
                  </v-btn>
                </template>
                <labeler-assignment-modal
                  :active.sync="isAssignmentDialogOpen"
                  :selectedItems="selected_tasks"
                  @close="isAssignmentDialogOpen = false"
                  :assign="assign"
                  :disabled="!selected_labeler"
                >
                  <template v-slot:body>
                    <v-select
                      v-model="selected_labeler"
                      :items="getLabelersWithNull"
                      item-text="email_address"
                      item-value="id"
                      label="Labeler"
                      hide-details
                      clearable
                    >
                      <template #item="{ item }">
                        <div :class="
                          item.email_address === 'Anyone' ? 'divider-top' : null
                        ">
                          {{ item.email_address }}
                        </div>
                      </template>
                      <template v-slot:append-item>
                        <v-divider class="mb-2"></v-divider>
                        <v-pagination
                          :value="getLabelersPage"
                          :length="getLabelersNbPages"
                          @input="updatePageLabelers"
                        ></v-pagination>
                      </template>
                    </v-select>
                  </template>
                </labeler-assignment-modal>
              </v-col>
            </v-row>
          </template>

          <template v-slot:item.assignee_id="{ item }">
            {{ item.labeler_email || item.assignee_id }}
          </template>
        </v-data-table>
      </div>
      <div class="footer">
        <v-pagination
          :value="getPage"
          :length="getNbPages"
          total-visible="12"
          @input="updatePage"
        ></v-pagination>
        <div class="input">
          <v-text-field
            :value="getTasksPerPage"
            label="Items per page"
            type="number"
            min="1"
            max="100"
            @change="updateNbTasksPerPage"
          ></v-text-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import LabelerAssignmentModal from '../components/LabelerAssignmentModal.vue'

export default {
  name: 'labeler',
  components: {
    LabelerAssignmentModal,
  },
  computed: {
    ...mapGetters('labels', ['getLabelSchemas']),
    ...mapGetters('auth', ['getUserId']),
    ...mapGetters('labelers', [
      'getLabelers',
      'getLabelersPage',
      'getLabelersNbPages',
    ]),
    ...mapGetters('datasets', ['getDatasets']),
    ...mapGetters('tasks', [
      'getTasks',
      'getPage',
      'getTasksPerPage',
      'getNbPages',
      'getSchemaFilter',
      'getStatusFilter',
      'getAssignementFilter',
      'getOrderByCreatedAt',
      'getFilterOnDataset',
      'getAllTasks',
      'getSelectedDataset',
      'getCurrentLabelerId',
    ]),
    ...mapGetters('labelers', ['getLabelers']),
    getLabelSchemasWithAll: function () {
      let label_schema = Array.from(this.getLabelSchemas)
      label_schema.push('all')
      return label_schema
    },
    getLabelersWithNull() {
      return this.getLabelers.concat([{ email_address: 'Anyone', id: 'any' }])
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Transaction',
          align: 'start',
          value: 'tx_description',
          sortable: false,
        },
        { text: 'Task', value: 'schema_version' },
        { text: 'Status', value: 'status' },
        { text: 'Assignee', value: 'assignee_id', sortable: false },
        { text: 'Date', value: 'created_at' },
      ],
      status_filters_options: ['all', 'todo', 'done', 'in_review', 'cancelled'],
      assignement_filters_options: ['any', 'assigned', 'unassigned', 'myself'],
      selected_tasks: [],
      selected_labeler: null,
      filter_on_dataset: false,
      isAssignmentDialogOpen: false,
      transactionDescription: '',
      selectedDataset: null,
      isFilterMenuOpen: false,
      isSortMenuOpen: false,
      selectedSortingOrder: null,
      selectedOrderBy: null,
      sortingOrders: ['asc', 'desc'],
      orderByValues: ['created_at'],
      isRetrievingTasks: false,
      selectedAssignmentFilter: null,
    }
  },
  methods: {
    ...mapActions('labels', ['retrieveLabelSchemas']),
    ...mapActions('tasks', [
      'retrieveAllTasks',
      'retrieveTasksForLabeler',
      'handleGetAllTasks',
    ]),
    ...mapActions('task', ['createTask', 'assignTask']),
    ...mapActions('datasets', [
      'retrieveDatasets',
      'retrieveDatasetTransactionIds',
    ]),
    ...mapActions('labelers', ['retrieveLabelers']),
    ...mapMutations('task', ['setTask']),
    ...mapMutations('tasks', [
      'setTasksPerPage',
      'setPage',
      'setSchemaFilter',
      'setStatusFilter',
      'setAssignementFilter',
      'setCurrentLabelerId',
      'setOrderByCreatedAt',
      'setFilterOnDataset',
      'setTransactionDescription',
      'setSelectedDataset',
      'setSortingOrder',
    ]),
    ...mapMutations('labelers', [
      'setLabeler',
      'setLabelersPerPage',
      'setLabelersPage',
      'setActiveFilter',
      'setOrderByNameFilter',
      'setIncludeAdminsFilter',
    ]),
    async retrieveTasks() {
      this.isRetrievingTasks = true
      try {
        if (this.getAssignementFilter === 'myself') {
          this.setCurrentLabelerId(this.getUserId)
          this.retrieveTasksForLabeler()
        } else {
          this.setCurrentLabelerId(null)
          await this.retrieveAllTasks()
        }

        this.error = false
      } catch (error) {
        this.error = true
        this.errorMessage = error
      }
      this.isRetrievingTasks = false
    },
    async updateNbTasksPerPage(e) {
      this.setPage(1)
      e = parseInt(e)
      if (e != '') {
        if (e < 1) {
          e = 1
        }
        if (e > 100) {
          e = 100
        }
        this.setTasksPerPage(e)
        await this.retrieveTasks()
      }
    },
    async updatePage(e) {
      this.setPage(e)
      await this.retrieveTasks()
    },
    async updatePageLabelers(e) {
      this.setActiveFilter(true)
      this.setOrderByNameFilter(true)
      this.setIncludeAdminsFilter(true)
      this.setLabelersPerPage(50)
      this.setLabelersPage(e)
      await this.retrieveLabelers()
    },
    async updateSchemaFilter(e) {
      this.setPage(1)
      this.setSchemaFilter(e)
    },
    async updateStatusFilter(e) {
      this.setPage(1)
      this.setStatusFilter(e)
    },
    async updateAssignementFilter(e) {
      this.selectedAssignmentFilter = e
    },
    async handleSearchByDescription(e) {
      if (!this.transactionDescription) {
        this.setTransactionDescription('')
      } else {
        this.setTransactionDescription(e)
      }
      this.setPage(1)
      await this.retrieveTasks()
    },
    async updateOrderByCreatedAt(e) {
      this.setPage(1)
      if (e == null) {
        e = false
      }
      this.setOrderByCreatedAt(e)
      await this.retrieveTasks()
    },
    handleDatasetChange(e) {
      if (!e) {
        this.setSelectedDataset(null)
      } else {
        this.setSelectedDataset(e.name)
      }
    },
    async selectAllTasks() {
      await this.handleGetAllTasks()
      this.selected_tasks = this.getAllTasks
    },
    async deselectAllTasks() {
      this.selected_tasks = []
    },
    handleClickRow(value) {
      this.$router.push(`/task/${value.id}`)
    },
    getDatasetFieldText(item) {
      return `${item.name} - (${item.size} txs) - ${item.updated_at}`
    },
    async createTasksForAnyone() {
      if (this.selected_tasks.length > 0) {
        this.selected_tasks.forEach((task) => {
          const { transaction_id, schema_version } = task
          this.createTask({
            transaction_id,
            schema_version,
          })
        })
      }
    },
    async assign() {
      this.isAssignmentDialogOpen = false

      if (this.selected_labeler === 'any') {
        try {
          await this.createTasksForAnyone()
        } catch (error) {
          this.$toasted.error(error)
        }
      } else {
        await Promise.all(
          this.selected_tasks.map(async (task) => {
            await this.assignTask({
              task_id: task.id,
              assignee_id: this.selected_labeler,
            })
          })
        )
      }

      await this.retrieveTasks()
      this.selected_tasks = []
    },
    async onUpdateFilters() {
      if (
        this.assignement_filters_options.includes(this.selectedAssignmentFilter)
      ) {
        this.setPage(1)
        this.setAssignementFilter(this.selectedAssignmentFilter)
        await this.retrieveTasks()
      } else {
        this.setCurrentLabelerId(this.selectedAssignmentFilter)
        await this.retrieveTasksForLabeler()
      }
    },
    async onUpdateSortFilters() {
      this.setSortingOrder(this.selectedSortingOrder)
      this.setOrderByCreatedAt(this.selectedOrderBy === 'created_at')
      await this.retrieveTasks()
    },
    reset() {
      this.setTransactionDescription('')
      this.setOrderByCreatedAt(false)
      this.setSortingOrder(null)
      this.setAssignementFilter('any')
      this.setStatusFilter('all')
      this.setSelectedDataset(null)
      this.setSchemaFilter('all')
      this.setTransactionDescription(null)
      this.setPage(1)
    },
    async onLoad() {
      this.reset()
      await this.retrieveDatasets()
      if (this.getLabelSchemas == null || this.getLabelSchemas.length == 0) {
        await this.retrieveLabelSchemas()
      }
      await this.retrieveTasks()
      await this.updatePageLabelers(1)
      await this.retrieveLabelers()
      if (this.getLabelers) {
        this.getLabelers.forEach((labeler) => {
          this.assignement_filters_options = [
            ...this.assignement_filters_options,
            {
              text: labeler.name,
              value: labeler.id,
            },
          ]
        })
      }
    },
  },
  async created() {
    this.onLoad()
  },
}
</script>

<style></style>